import {graphql} from 'gatsby'
import React from 'react'
import Page from '../components/Layout/Page'

const NotFound = () => {
    return <Page>Page not found</Page>
}

export default NotFound

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
